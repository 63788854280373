import React from 'react';
import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom';


function OurStory() {

    const navigate = useNavigate();

    return (
        <div className="flex h-full items-center justify-center   text-white text-center "
            style={{
                backgroundImage: `url(/images/ourstory.png)`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '700px',
                width: '100%',
                position: 'relative'
            }}
        >
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.3)',  // Dark overlay with 50% opacity
            }}></div>
            <div className='z-50 xl:mx-96 mx-7'>
                <h1 className='lg:text-6xl text-2xl'>Forged in Resilience,<br></br> The Journey Behind Swift Finds</h1>
                <div className="flex justify-center m-5">
                    <Button size="lg" className='px-5' gradientDuoTone="redToYellow" onClick={() => navigate(`/about-us`)}>
                        Our Story
                    </Button>
                </div>
            </div>

        </div>
    );
}

export default OurStory;