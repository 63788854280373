import React from 'react';
import CarouselComponent from './Carousel';
import './../../App.css'
import FeatureProducts from './FeatureProducts';
import Categories from './Categories';
import Testimonials from './Testimonials';
import Logos from './Logos';
import OurStory from './OurStory';
import Policy from './Policy';
import { Accordion } from "flowbite-react";


function MainPage() {

    return (
        <div>
            <CarouselComponent/>
            <FeatureProducts/>
            <Testimonials />
            <Logos />
            <OurStory />
            <Policy />
        </div>
    );
}

export default MainPage;