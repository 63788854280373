import React from 'react';
import { Accordion } from "flowbite-react";

function Policy() {

    return (
        <>
            <div className='lg:mx-20 mx-5 my-10 policy'>
                <h1>Policy & Procedure - Personalized Gifts</h1>

                <Accordion className=' border-t mt-5 text-sm text-black'>
                    <Accordion.Panel>
                        <Accordion.Title><p>
                            POLICY & PROCEDURE
                        </p>
                        </Accordion.Title>
                        <Accordion.Content>
                            <p>
                                Our workflow is designed to provide you with fast and efficient service, without compromising on quality. We prioritize meeting your delivery expectations and exceeding your crafting needs.
                                <br />
                                <br />
                                Thank you for choosing us for your personalized engraved gifts.
                            </p>
                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title><p>
                            I. PROCESS
                        </p>
                        </Accordion.Title>
                        <Accordion.Content>
                            <h1 className='font-bold'>
                                ORDER
                            </h1>
                            <ul>
                                <li><p>
                                    As soon as you place your order with us, we’ll jump into action and begin processing it without delay. Our streamlined process ensures that your order is handled promptly and efficiently, so you can receive your items as soon as possible.
                                </p>
                                </li>
                                <li>Please be advised that all aspects of your transaction will be handled via our facebook page. For your convenience and to ensure clear communication, please keep all changes, requests, and additional information related to your order within the same facebook page thread. This helps us keep everything organized and ensures that we can provide you with the best possible service. 😉</li>
                                <li>Once we have completed the design phase, we will send you a notification with the expected date for your approval. (Please note that this is not the finished product, but rather a design mockup for your review and feedback.)</li>
                            </ul>

                            <br />
                            <h1 className='font-bold'>
                                DESIGN
                            </h1>

                            <ul>
                                <li>
                                    <p>
                                        We begin the design process for your order as soon as we receive it. Our talented team of designers will work tirelessly to bring your vision to life and ensure that you are completely satisfied with the end result.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Please be advised that our artists are available to work on your order from 9am to 8pm daily. We strive to accommodate your scheduling needs and will work diligently to ensure that your project is completed on time and to your satisfaction.                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Please note that the design process for your order typically takes between 3-5 working days. Our team of talented designers will work tirelessly to ensure that your vision is brought to life and that you are completely satisfied with the end result.                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Once we have completed the design phase, we will send you a notification with the design for your approval. Please note that this is not the finished product, but rather a design mockup for your review and feedback.                    </p>
                                </li>
                                <li>
                                    <p>
                                        Please note that design approval is only applicable for products that require vector or line art. For other types of products, we will send you a sample image for your review and feedback.               </p>                   </li>
                                <li>
                                    <p>
                                        Revisions to your design typically take between 2-3 days. Please note that the duration of the design process may vary depending on your response time, as we aim to work closely with you to ensure that your vision is brought to life.   </p>                               </li>
                                <li>
                                    <p>
                                        After you have approved the design, we will move it into production. Our team of skilled craftsmen will work diligently to ensure that your product is crafted with the utmost care and attention to detail.</p>                                </li>
                            </ul>

                            <br />
                            <h1 className='font-bold'>
                                PRODUCTION
                            </h1>

                            <ul>
                                <li>
                                    <p>
                                        Processing
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Packaging
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        3-5 working days
                                    </p>
                                </li>
                            </ul>

                            <br />
                            <h1 className='font-bold'>
                                SHIPPING
                            </h1>

                            <p>Once your order is complete, we will hand it over to our trusted partner couriers for delivery. Please take a moment to review our shipping policy below for more information. 😉

                            </p>


                        </Accordion.Content>
                    </Accordion.Panel>
                    <Accordion.Panel>
                        <Accordion.Title><p>
                            II. LEAD TIME
                        </p>
                        </Accordion.Title>
                        <Accordion.Content>
                            <ul>
                                <li>
                                    <p>
                                        As soon as we confirm your payment, we will begin processing your order immediately. Our streamlined process ensures that your order is handled promptly and efficiently, with a typical processing time of 7-10 working days.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Once your order is complete, we will entrust it to our reliable partner courier for delivery. Our team works tirelessly to ensure that your product is carefully packaged and shipped to you in a timely and efficient manner.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        For customers located in Metro Manila, we offer free shipping with a delivery time of 2-3 days. If you require rush delivery, we can arrange for a courier service such as Lalamove, Grab, or Toktok, with the shipping fee to be shouldered by the customer.                                    </p>
                                </li>
                                <li>
                                    <p>
                                        For customers outside Metro Manila, we also offer free shipping with a delivery time of 3-8 days or depends on your location. If you require rush delivery, we can arrange for LBC courier service, with the shipping fee to be shouldered by the customer.                          </p>
                                </li>
                            </ul>
                        </Accordion.Content>
                    </Accordion.Panel>

                </Accordion>
            </div>
        </>
    );
}

export default Policy;