import { useState, useEffect } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom'
import './../../App.css'
import productArray from '../components/productDatas';
import { Carousel } from "flowbite-react";
import { Card, Rating } from "flowbite-react";
import { useNavigate } from 'react-router-dom';






function ProductCard(props) {
    const item = props.item
    const index = props.index
    const [hovered, setHovered] = useState({});
    const navigate = useNavigate();

    function navigateToPage(link) {
        navigate(`/products/${item.productSlug}`);
        window.scrollTo(0, 0); // Scroll to the top of the page
    }

    function DynamicRating(rating) {
        const totalStars = 10; // Keep total stars as 10
        const displayedStars = 5; // Number of stars to display visually
    
        // Calculate the scaled rating for the 5-star display
        const scaledRating = (rating / totalStars) * displayedStars;
    
        // Create an array of stars based on the scaled rating
        const stars = Array.from({ length: displayedStars }, (_, index) => {
            return index < Math.floor(scaledRating) ? (
                <Rating.Star key={index} />
            ) : index < scaledRating ? (
                <Rating.Star key={index} half />
            ) : (
                <Rating.Star key={index} filled={false} />
            );
        });
    
        return (
            <Rating size="sm">
                {stars}
                <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                    {rating} out of {totalStars} Reviews
                </p>
            </Rating>
        );
    }

    return (
        <>
            {item &&
                <Card className="mx-5 mb-10 cursor-pointer bg-slate" onMouseEnter={() => setHovered({ index: index, hovered: true })}
                    onMouseLeave={() => setHovered({})} onClick={() => navigateToPage(`/products/${item.productSlug}`)}>
                    <div className="relative">
                        <img className='h-60 w-full object-cover' src={`${item.pictures[0]}`} alt="image" style={{
                            opacity: !hovered.index || (hovered.index !== index) ? 1 : 0,
                            transition: 'opacity 0.3s ease-in-out',
                        }} />
                        <img className='h-60 w-full object-cover' src={`${item.pictures[1]}`} alt="image" style={{
                            opacity: hovered.index === index ? 1 : 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            transition: 'opacity 0.3s ease-in-out',
                        }} />
                        <p className="absolute top-2 left-2 bg-burgundy text-sm text-white px-4 py-2 shadow-md">
                            {item.alert}
                        </p>
                    </div>
                    <p className='text-sm text-gray-400'>{item.productTitle}</p>
                    <p className={`text-lg font-extrabold text-burgundy  ${hovered.index === index ? 'underline transition-opacity' : 0}`}>{item.productName}</p>
                    <div className=" flex items-center">
                        {DynamicRating(item.rating)}
                    </div>
                    <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">&#8369;{item.price}</p>
                </Card>
            }
        </>
    );
}

export default ProductCard;