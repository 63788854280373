import { useState } from 'react';
import React from 'react';
import logo from '../../images/sf-main-logo (2).png'
import { ListGroup } from "flowbite-react";
import { Card } from "flowbite-react";
import Carousel from 'react-multi-carousel';
import { Rating } from "flowbite-react";


function Testimonials() {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1601 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 1600, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const data = [

        { photo: '/images/review2.jpg', comment: "I ordered engraved bamboo pens and keychains for my event, and they were perfect! The quality of the engraving is exceptional, and the items have such a unique, natural feel. Plus, the customer service was top-notch—Swift Finds made the whole process so easy and stress-free. My guests absolutely loved the souvenirs!", name: 'Calvin', product: 'Bamboo Pen & Wooden Keychains' },
        { photo: '/images/review1.jpg', comment: '"This desk pad is a game changer! The engraving on the leather is sleek and sophisticated, adding a personal touch to my workspace. The two-tone design is both functional and stylish. I also have to give a shoutout to the amazing customer service—they were so responsive and helpful throughout!"', name: 'Cristel', product: 'Two-Tone Leather Desk Pad' },
        { photo: '/images/review4.jpg', comment: "I bought the engraved wooden ref magnets and bamboo mirror as gifts, and they exceeded my expectations. The craftsmanship is fantastic, and the items arrived beautifully packaged. Swift Finds' customer service was exceptional—they kept me updated every step of the way!", name: 'Joshua', product: 'Wooden Ref Magnets & Bamboo Mirror' },
        { photo: '/images/review3.jpg', comment: "The engraved wooden bottle opener I purchased is so charming! It's sturdy, beautifully designed, and the engraving really stands out. I was also really impressed with the customer service—they were so accommodating and made sure everything was just right.", name: 'Katherine', product: 'Wooden Bottle Opener Round' },
        { photo: '/images/review5.jpg', comment: "I’m beyond happy with the engraved wooden headphone stand I ordered! It’s both practical and stylish, and the engraving adds such a personal touch. The customer service was exceptional—Swift Finds went above and beyond to ensure everything was perfect. Highly recommend!", name: 'Chaline', product: 'Headphone Stand' },


    ]
    return (
        <div className='mx-5 mb-20'>
            <p className='text-center libre-baskerville-regular'>CUSTOMER REVIEWS</p>
            <p className='text-center text-bold text-4xl'>What our customers are saying</p>
            <div>
                <Carousel responsive={responsive}>
                    {data.map((item, index) => (
                        <Card className="mt-10 h-full" renderImage={() => <img className='sm:w-80 w-full h-full' src={item.photo} alt="image 1" />} horizontal>
                            <Rating>
                                <Rating.Star />
                                <Rating.Star />
                                <Rating.Star />
                                <Rating.Star />
                                <Rating.Star filled={false} />
                            </Rating>
                            <h5 className="text-md tracking-tight text-gray-900 dark:text-white libre-baskerville-regular-italic-bold">
                                {item.comment}
                            </h5>
                            <p className="font-normal text-gray-700 dark:text-gray-400">
                                <span className='text-sm libre-baskerville-regular'>
                                    {item.name} |
                                </span>
                                <span className='font-semibold'>
                                &nbsp; {item.product}
                                </span>
                            </p>
                        </Card>

                    ))}
                </Carousel>
            </div>
        </div>
    );
}

export default Testimonials;