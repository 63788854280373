import { useState } from 'react';
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import logo from '../../images/sf_main_logo.png'
import tiktok from '../../images/tiktok_3116490.png';
import facebook from '../../images/social_12942738.png';
import instagram from '../../images/instagram_1419647.png';

import { Carousel } from "flowbite-react";
import { Button } from "flowbite-react";


function CarouselComponent() {

    const customTheme = {
        "scrollContainer": {
            "base": "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth",
            "snap": "snap-x"
        }
    };
    return (
        <div>
            <div className="h-100 sm:h-64 xl:h-100 2xl:h-96" style={{ height: 650 }}>
                <Carousel theme={customTheme} slide={true}>
                    <div className="flex h-full items-center justify-center   text-white text-center "
                        style={{
                            // backgroundImage: `url(https://images.pexels.com/photos/122588/pexels-photo-122588.jpeg?auto=compress&cs=tinysrgb&w=600)`,
                            // backgroundSize: 'cover',
                            width: '100%',
                        }}
                    >
                        <img
                            src="/images/test.gif"  // Replace with your GIF URL
                            alt="Animated GIF"
                            className="absolute top-0 left-0 w-full h-full object-cover"
                            style={{ objectFit: 'cover' }} // Ensure the GIF covers the entire area
                        />

                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.4)',  // Dark overlay with 50% opacity
                        }}></div>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                            <p className='swift-finds'>Swift Finds</p>
                            <h1 className='text-md md:text-lg lg:text-4xl font-bold leading-loose'>Crafted with Care: Unique, Personalized Souvenirs</h1>
                            <h3 className='text-sm md:text-md lg:text-2xl leading-loose'>Handmade Keepsakes Engraved with Your Memories, Perfect for Every Occasion</h3>
                            <div className="flex justify-center m-5">
                                <Button size="lg" outline gradientDuoTone="redToYellow" onClick={() => window.open('https://ph.shp.ee/tMddwY5', '_blank')}>
                                    Shop The Craft!
                                </Button>
                            </div>
                        </div>

                    </div>
                
                </Carousel>
            </div>
        </div>
    );
}

export default CarouselComponent;