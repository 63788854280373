import { useState } from 'react';
import React from 'react';
import logo from '../../images/sf-main-logo-cut.png'
import { useNavigate } from 'react-router-dom';


function Header() {
    const navigate = useNavigate();
    return (
        <div>
            <header>
                <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 bg-slate">
                    <div class="flex flex-wrap justify-center items-center mx-auto max-w-screen-xl">
                        <div className='pb-3'>
                            <a  className="cursor-pointer" onClick={() => navigate(`/`)}>
                                <img src={logo} alt="Logo" class="mr-3 lg:h-11 sm:h-9" style={{ height: 100 }} />
                            </a>
                        </div>

                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Header;