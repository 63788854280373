import { useState } from 'react';
import React from 'react';
import { Footer } from "flowbite-react";
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter, BsTiktok } from "react-icons/bs";


function FooterComponent() {

    return (
        <Footer container className='bg-slate rounded-none'>
            <div className="w-full ">
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
                    <div>

                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                        <div>
                            <Footer.Title title="about" className='text-white' />
                            <Footer.LinkGroup col className='text-white'>
                                <Footer.Link href="/about-us">Swift Finds</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Legal" className='text-white' />
                            <Footer.LinkGroup col className='text-white' >
                                <Footer.Link href="#">Privacy Policy</Footer.Link>
                                <Footer.Link href="#">Terms &amp; Conditions</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Contact and Follow us" className='text-white' />
                            <Footer.LinkGroup col className='text-white' >
                                <Footer.Link href="https://www.facebook.com/swiftfindsofficial" className=''>Facebook</Footer.Link>
                                <Footer.Link href="https://www.tiktok.com/@swiftfindsofficial">Tiktok</Footer.Link>
                                <Footer.Link href="">+639923854877</Footer.Link>
                                <Footer.Link href="">swiftfindsofficial@gmail.com</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between text-white">
                    <Footer.Copyright href="#" by="Swift Finds" year={2024} className='text-white' />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center text-white">
                        <Footer.Icon href="https://www.tiktok.com/@swiftfindsofficial" icon={BsFacebook} />
                        <Footer.Icon href="https://www.tiktok.com/@swiftfindsofficial" icon={BsTiktok} />
                    </div>
                </div>
            </div>
        </Footer>
    );
}

export default FooterComponent;