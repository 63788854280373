import { useState } from 'react';
import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Card, Rating } from "flowbite-react";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import { Button } from "flowbite-react";
import productArray from './../components/productDatas';
import { useNavigate } from 'react-router-dom';
import ProductCard from '../components/ProductCard';


function FeatureProducts() {
    const [hovered, setHovered] = useState({});
    const navigate = useNavigate();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    function loopCard() {
        return (
            <div className='py-7 xl:grid-cols-4 2xl:grid-cols-4 md:grid-cols-2 lg:grid-cols-3 grid-cols-2 lg:grid'>
                {productArray.map((item, index) => (
                    <ProductCard item={item} index={index} />
                ))}
            </div>
        )
    }

    function loopCarouselCard() {
        return (
            <div className='lg:hidden mt-8'>
                <Carousel responsive={responsive}>
                    {productArray.map((item, index) => (
                        <ProductCard item={item} index={index}  />
                    ))}
                </Carousel>
            </div>
        )
    }

    return (
        <div className='2xl:mx-72 xl:mx-30 lg:mx-10 md:mx-10 mx-5 mt-5'>
            <div className='flex justify-between lg:mx-5'>
                <h1 className='font-extrabold lg:text-2xl text-base'>Product starting @ ₱35 -></h1>
                <Button size="lg" className='hidden lg:block bg-burgundy'>
                    Shop new products
                </Button>
            </div>
            {loopCard()}
            {/* {loopCarouselCard()} */}
            <div>
                <Button size="lg" className='lg:hidden w-full mb-5 -mt-5 bg-burgundy'>
                    Shop Bestsellers
                </Button>
            </div>
        </div >
    );
}

export default FeatureProducts;