import { useState, useEffect } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom'
import './../../App.css'
import productArray from '../components/productDatas';
import { Carousel } from "flowbite-react";
import { Card, Rating, Modal  } from "flowbite-react";
import CarouselV2 from 'react-multi-carousel';
import ProductCard from '../components/ProductCard';
import { Button } from "flowbite-react";
import { Breadcrumb } from "flowbite-react";
import { HiHome } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

function ProductPage() {
    const { product_name } = useParams()
    const [itemData, setItemData] = useState({})
    const [openModal, setOpenModal] = useState(false);
    const navigate = useNavigate();

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    function DynamicRating(rating) {
        const totalStars = 10; // Keep total stars as 10
        const displayedStars = 5; // Number of stars to display visually
    
        // Calculate the scaled rating for the 5-star display
        const scaledRating = (rating / totalStars) * displayedStars;
    
        // Create an array of stars based on the scaled rating
        const stars = Array.from({ length: displayedStars }, (_, index) => {
            return index < Math.floor(scaledRating) ? (
                <Rating.Star key={index} />
            ) : index < scaledRating ? (
                <Rating.Star key={index} half />
            ) : (
                <Rating.Star key={index} filled={false} />
            );
        });
    
        return (
            <Rating size="sm">
                {stars}
                <p className="ml-2 text-sm font-medium text-gray-500 dark:text-gray-400">
                    {rating} out of {totalStars} Reviews
                </p>
            </Rating>
        );
    }

    function formattedText(text) {

        const returnText = text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));

        return returnText;
    }



    useEffect(() => {
        if (product_name) {
            const productData = productArray.find(item => item.productSlug === product_name);
            setItemData(productData);
        }
    }, [product_name])

    useEffect(() => {
        const updateScrollableHeight = () => {
            const contentDiv = document.getElementById('contentDiv');
            const scrollableDiv = document.getElementById('scrollableDiv');

            if (contentDiv && scrollableDiv) {
                scrollableDiv.style.minHeight = `${contentDiv.offsetHeight}px`;
            }
        };

        // Update height on component mount
        updateScrollableHeight();

        // Update height on window resize
        window.addEventListener('resize', updateScrollableHeight);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateScrollableHeight);
        };
    }, [itemData]);
    return (
        <div>

            {itemData.productSlug &&
                <>
                <Breadcrumb aria-label="Default breadcrumb example" className='mx-5 my-5 xl:mx-80 lg:mx-10 my-10'>
                    <Breadcrumb.Item className='cursor-pointer font-bold text-black' onClick={() => navigate(`/`)}>
                        <HiHome className="text-black mr-1" />
                        <p className='font-bold text-black'>
                            Home
                        </p>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Products</Breadcrumb.Item>
                    <Breadcrumb.Item>{itemData.productName}</Breadcrumb.Item>
                </Breadcrumb>
                    <div className='grid grid-cols-1 lg:grid-cols-2 xl:mx-80 lg:mx-10 my-10'>
                        <div className='grid grid-cols-1 md:grid-cols-2 hidden lg:grid w-full scrollable-container hidden lg:grid w-full overflow-auto' id='scrollableDiv'>
                            {itemData.pictures.map(item => (
                                <div className='p-2 cursor-pointer' key={item} onClick={() => setOpenModal(item)}>
                                    <img className='h-96 w-full object-cover' src={`${item}`} alt="image" />
                                </div>
                            ))}
                        </div>
                    <div className="h-80 sm:h-96 xl:h-80 2xl:h-96 lg:hidden mx-5 my-5">
                            <Carousel>
                                {itemData.pictures.map(item => {
                                    return (
                                        <div className='p-2' onClick={() => setOpenModal(item)}>
                                            <img className='h-96 w-full object-cover' src={`${item}`} alt="image" />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                        <div className='p-11 w-full' id='contentDiv'>
                            <h1 className='text-4xl font-extrabold'>{itemData.productName}</h1>
                            <br />
                            <p className='text-lg'>&#8369;{itemData.price}</p>
                            <br />
                            <p className='text-gray-500'>{formattedText(itemData.description)}</p>
                            <br />
                            <div className="flex items-center">
                                {DynamicRating(itemData.rating)}
                            </div>
                            <div className='lg:px-10'>
                                <br />
                                <p className='text-center'>Do you want it? Just click the links below</p>
                                {itemData.links.map(item => (
                                    <Button className='w-full bg-burgundy my-3' onClick={() => window.open(item.link, '_blank')} key={item.name}>{item.name}</Button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                    <div className='xl:mx-80 lg:mx-10 my-10'>
                        <h1 className='text-3xl text-center'>Related Products</h1>
                    </div>

                    <CarouselV2 responsive={responsive}>
                    {productArray
                        .filter(item => item.productSlug !== itemData.productSlug) // Filter out items with productSlug equal to '123'
                        .map((item, index) => (
                            <ProductCard key={index} item={item} index={index} />
                        ))}
                </CarouselV2>
                </>
            }

            <Modal show={openModal} onClose={() => setOpenModal(false)} dismissible >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <img className='w-full object-cover' src={`${openModal}`} alt="image" />
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default ProductPage;