import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './pages/components/Header';
import MainPage from './pages/LandingPage/Main';
import './App.css';
import ProductPage from './pages/ProductPage/ProductPage';
import FooterComponent from './pages/LandingPage/Footer';
import loadingGif from './images/loading.gif'; // Adjust the path as needed
import { useEffect, useState } from 'react';
import AboutUs from './pages/LandingPage/AboutUs';
import { FacebookProvider, CustomChat } from 'react-facebook';

function App() {

  const [allLoaded, setAllLoaded] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [totalLoadables, setTotalLoadables] = useState(0);

  useEffect(() => {
    const loadables = document.querySelectorAll('img, video');
    setTotalLoadables(loadables.length); // Total number of images and videos

    if (loadables.length === 0) {
      // No loadables present, mark as loaded immediately
      setAllLoaded(true);
    } else {
      // Attach event listeners to all images and videos
      loadables.forEach((element) => {
        // Image load handler
        const onLoad = () => {
          setLoadingCount((prev) => prev + 1);
        };

        // Check if the element is already loaded (cache or fast load)
        if ((element.tagName === 'IMG' && element.complete) || (element.tagName === 'VIDEO' && element.readyState >= 3)) {
          onLoad();
        } else {
          // Add event listeners
          if (element.tagName === 'IMG') {
            element.addEventListener('load', onLoad);
            element.addEventListener('error', onLoad); // Consider error as a "loaded" state for simplicity
          } else if (element.tagName === 'VIDEO') {
            element.addEventListener('loadeddata', onLoad);
          }
        }
      });
    }

    return () => {
      // Cleanup listeners to avoid memory leaks
      loadables.forEach((element) => {
        if (element.tagName === 'IMG') {
          element.removeEventListener('load', () => { });
          element.removeEventListener('error', () => { });
        } else if (element.tagName === 'VIDEO') {
          element.removeEventListener('loadeddata', () => { });
        }
      });
    };
  }, []);

  let showload = 0

  useEffect(() => {
    if (loadingCount >= totalLoadables && totalLoadables > 0) {
      setAllLoaded(true);
    }

    setInterval(() => {
      if (showload > 10) {
        setAllLoaded(true)
      }
      else {
        showload += 1;
      }
    }, 1000);
  }, [loadingCount, totalLoadables]);
  console.log('object :>> ', loadingCount, totalLoadables, allLoaded);
  return (
    <div>
      <div className={`flex justify-center items-center h-screen bg-slate ${allLoaded && 'hidden'}`}>
        <img src={loadingGif} alt="Loading..." className='lg:h-96' />
      </div>
      <div className={`flex flex-col min-h-screen ${!allLoaded && 'hidden'}`}>
      {/* Header */}
      <Header />

      {/* Main content with flex-grow */}
      <div className="flex-grow">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="products/:product_name" element={<ProductPage />} />
        </Routes>
      </div>
      <FacebookProvider appId="560398113217567" chatSupport>
        <CustomChat pageId="390071410855993" minimized={true}/>
      </FacebookProvider>    

      {/* Footer */}
      <FooterComponent />

      </div>

    </div>

  );
}

export default App;