const productArray = [
    {
        productName: "Bamboo Pen",
        productSlug: "bamboo-pen",
        productTitle: "Wooden Craft",
        "categories": 'Wooden Pen',
        rating: 8,
        description: `Eco-friendly and elegant, our bamboo pens are crafted from sustainable materials and can be personalized with engraved text or images. Each pen combines functionality with a unique, customized touch, making it a meaningful and stylish choice.
Perfect for corporate giveaways, weddings, special events, or thoughtful gifts, these bamboo pens offer a lasting impression. Lightweight, durable, and versatile, they're ideal for any occasion that calls for a personalized touch.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/PEN/BALLPEN SET 3.png',
            '/images/productDatas/PEN/BP 1.png',
            '/images/productDatas/PEN/PEN NEW EDIT 1.png',
            '/images/productDatas/PEN/PEN NEW EDIT 3.png',
            '/images/productDatas/PEN/PEN NEW EDIT 4.png',
            '/images/productDatas/PEN/KEYCHAIN.png',
            '/images/productDatas/PEN/BALLPEN SET 1.png',
            '/images/productDatas/PEN/BALLPEN SET 4.png',
            '/images/productDatas/PEN/PEN W TAG 4.png',
            '/images/productDatas/PEN/PEN W TAG 3.png',
            '/images/productDatas/PEN/PEN W TAG 2.png',
            '/images/productDatas/PEN/PEN PRINTED SLEEVE 3.png',
            '/images/productDatas/PEN/PEN PRINTED SLEEVE 1.png',
            '/images/productDatas/PEN/PEN PRINTED SLEEVE 2.png',
            '/images/productDatas/PEN/PEN PRINTED SLEEVE 4.png',

        ],
        price: '35-₱50',
        alert: "NEW PRODUCT",
        links: [
            {
                name: "Shopee",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Bamboo-Pen-i.381171030.27663422362?sp_atk=1fda2c0b-a69a-49d6-b323-dab8d3cbd07c&xptdk=1fda2c0b-a69a-49d6-b323-dab8d3cbd07c"
            },
        ]
    },
    {
        productName: "Headphone Stand",
        productSlug: "headphone-stand",
        productTitle: "Wooden Craft",
        "categories": 'Headphone Stand',
        rating: 7,
        description: `Elevate your workspace with our handcrafted wooden headphone stand. Designed for both style and functionality, this stand offers a sleek and organized solution for storing your headphones. The natural wood finish adds a touch of elegance to any desk or gaming setup while keeping your headphones easily accessible and tangle-free.
Perfect for music lovers, gamers, or professionals, our wooden headphone stand provides a sturdy and stylish display. Its minimalist design complements any décor, making it a thoughtful gift or a must-have accessory for anyone who values organization and aesthetics.

  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/WFH SET/HEADPHONE STND 2.png',
            '/images/productDatas/WFH SET/HEADPHONE STND 4.png',
            '/images/productDatas/WFH SET/HEADPHONE STND 3.png',
            '/images/productDatas/WFH SET/HEADPHONE STND 1.png',
            '/images/productDatas/WFH SET/HEADPHONE STND BOX 1.png',
            '/images/productDatas/WFH SET/WFH SET 1.png',
            '/images/productDatas/WFH SET/WFH SET 3.png',
            '/images/productDatas/WFH SET/WFH SET 2.png',
        ],
        price: '180-₱220',
        alert: "NEW PRODUCT",
        links :[
            {
                name : "Shopee",
                link : "https://shopee.ph/Laser-Engraved-Wooden-Headphone-Stand-i.381171030.26762913208?sp_atk=8b1a4756-a1cb-42b4-9cf0-75c5a4dfbca5&xptdk=8b1a4756-a1cb-42b4-9cf0-75c5a4dfbca5"
            },
        ]  
    }, 
    {
        productName: "Wooden Phone Stand",
        productSlug: "wooden-phone-stand",
        productTitle: "Wooden Craft",
        "categories": 'Stand',
        rating: 7,
        description: `Discover our elegant laser-engraved wooden phone stand, available in three stunning variants. Each stand is crafted from high-quality wood and features personalized designs, providing a secure hold for your phone at the perfect viewing angle.

Perfect for home or office use, this stand declutters your space while adding a touch of sophistication. It’s an ideal gift for tech enthusiasts, students, and professionals, making it a stylish addition to any desk, nightstand, or kitchen counter.`,
        pictures: [
            '/images/productDatas/PHONE STAND/PHONE STND KYCHN 1.png',
            '/images/productDatas/PHONE STAND/PHONE STND KYCHN 2.png',
            '/images/productDatas/PHONE STAND/PHONE STND KYCHN 3.png',
            '/images/productDatas/PHONE STAND/PHONE STND BG.png',
            '/images/productDatas/PHONE STAND/PHONE STND BG 2.png',
            '/images/productDatas/PHONE STAND/PHONE STND BG 3.png',
            '/images/productDatas/PHONE STAND/PHONE STAND FLAT NEW EDIT 2.png',
            '/images/productDatas/PHONE STAND/PHONE STAND FLAT NEW EDIT 1.png',
            '/images/productDatas/PHONE STAND/PHONE STAND FLAT NEW EDIT 3.png',
            '/images/productDatas/PHONE STAND/PHONE STAND KEYCHAIN BOX 1.png',
            '/images/productDatas/PHONE STAND/PHONE STAND KEYCHAIN BOX 3.png',
            '/images/productDatas/PHONE STAND/PHONE STND FLAT SET 1.png',
            '/images/productDatas/PHONE STAND/PHONE STND FLAT BOX 1.png',
            '/images/productDatas/PHONE STAND/PHONE STND KYCHN BOX 1.png',
            '/images/productDatas/PHONE STAND/PHONE STAND KEYCHAIN BOX 2.png',
            '/images/productDatas/PHONE STAND/PHONE STND BG SET 1.png',
            '/images/productDatas/PHONE STAND/PHONE STND BIG SET 2.png',
            '/images/productDatas/PHONE STAND/PHONE STND BG SET 1.png',

          
      
        ],
        price: '50-₱195',
        alert: "NEW PRODUCT",
        links: [
            {
                name: "Personalized Laser Engraved Wooden Phone Stand",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Wooden-Phone-Stand-i.381171030.28463204870?sp_atk=4fcdefd3-1604-4d41-92a5-eae19cef22f4&xptdk=4fcdefd3-1604-4d41-92a5-eae19cef22f4"
            },
            {
                name: "Personalized Laser Engraved Detachable Wooden Phone Stand",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Detachable-Wooden-Phone-Stand-i.381171030.29363173529?sp_atk=1d74560c-abeb-45d1-a35b-3e34f0aaaa0c&xptdk=1d74560c-abeb-45d1-a35b-3e34f0aaaa0c"
            },
            {
                name: "Personalized Laser Engraved Phone Stand Keychain",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Phone-Stand-Keychain-i.381171030.29963225711?sp_atk=848f53a1-46d6-40a1-89b7-eec27662697e&xptdk=848f53a1-46d6-40a1-89b7-eec27662697e"
            },
        ]
    },
    {
        productName: "Two-tone Leather Desk Pad",
        productSlug: "two-tone-leather-desk-pad",
        productTitle: "Leather Craft",
        "categories": 'Desk Pad',
        rating: 9,
        description: `Upgrade your workspace with our premium leather desk pad, customized with engraved text or designs. Crafted from high-quality faux leather, this desk pad provides a smooth surface for writing, typing, or using your mouse, while protecting your desk from scratches and spills. The personalized engraving adds a unique, professional touch that reflects your style.
        Ideal for office professionals, creatives, or anyone looking to enhance their workspace, this leather desk pad combines elegance with practicality. It's not only a functional accessory but also a statement piece that elevates any desk setup, making it a perfect gift or personal upgrade.
        
          STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

          FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/WFH SET/for add-desk pad3.png',
            '/images/productDatas/WFH SET/for add-desk pad2.png',
            '/images/productDatas/WFH SET/for add-desk pad1.png',
            '/images/productDatas/WFH SET/LEATHER DSKPAD 1.png',
            '/images/productDatas/WFH SET/LEATHER DSKPAD 2.png',
            '/images/productDatas/WFH SET/LEATHER 1.png',
            '/images/productDatas/WFH SET/DESKPAD SET 1.png',
            '/images/productDatas/WFH SET/WFH SET 1.png',
            '/images/productDatas/WFH SET/WFH SET 3.png',
            '/images/productDatas/WFH SET/WFH SET 2.png',
        ],
        price: '220-₱280',
        alert: "NEW PRODUCT",
        links: [
            {
                name: "Shopee",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Two-tone-Leather-Desk-Pad-i.381171030.26112818512?sp_atk=89404c44-5e1e-480b-8696-e73c3a0751fc&xptdk=89404c44-5e1e-480b-8696-e73c3a0751fc"
            },
        ]
    },
    {
        productName: "Mini Signage Stand",
        productSlug: "mini-signage-stand",
        productTitle: "Wooden Craft",
        "categories": 'Signage',
        rating: 7,
        description: `Showcase your message in style with our wooden engraved mini signage stand. Handcrafted from bamboo, this mini stand offers a natural, rustic charm, perfect for engraving personalized text, logos, or designs. Its compact size makes it an ideal accent for desks, shelves, or counters, adding a warm and unique touch to any space.
Perfect for weddings, events, or business displays, our wooden mini signage stand is both functional and decorative. Its construction and elegant engraving make it a versatile piece that can be used for table numbers, nameplates, or branding, making any occasion or space stand out with a personalized touch.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/SIGNAGES/SIGNAGE 1.png',
            '/images/productDatas/SIGNAGES/SIGNAGE 2.png',
            '/images/productDatas/SIGNAGES/SIGNAGE 3.png',
            '/images/productDatas/SIGNAGES/SIGNAGES 1.png',
            '/images/productDatas/SIGNAGES/SIGNAGES 2.png',
            '/images/productDatas/SIGNAGES/SIGNAGES 3.png',
            '/images/productDatas/SIGNAGES/SIGNAGE SET 2.png',
            '/images/productDatas/SIGNAGES/SIGNAGE SET 3.png',
        ],
        price: '85-₱125',
        alert: "NEW PRODUCT",
        links :[
            {
                name : "Shopee",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Mini-Signage-Standee-i.381171030.27513226467?sp_atk=b4b6b481-9501-42a2-8e15-6f17015fcd1f&xptdk=b4b6b481-9501-42a2-8e15-6f17015fcd1f"
            },
        ]  
    }, 

    {
        productName: "Tealight Candle Holder",
        productSlug: "tealight-candle-holder",
        productTitle: "Wooden Craft",
        "categories": 'Candle Holder',
        rating: 8,
        description:`Enhance your space with our Wooden Engraved Tealight Candle Holder, beautifully crafted from high-quality wood. This holder allows for custom text engravings, making it perfect for personal messages or special occasions. Its natural wood finish complements any decor style, adding a warm and inviting touch.
Ideal for cozy nights, romantic dinners, or thoughtful gifts, this candle holder transforms the soft glow of tealight candles into a serene ambiance. Celebrate your moments with this unique piece that combines functionality and elegance, creating a delightful atmosphere in your home.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/TEALIGHT/TEALIGHT 6.png',
            '/images/productDatas/TEALIGHT/TEALIGHT SET 2.png',
            '/images/productDatas/TEALIGHT/TEALIGHT HOLDER 1.png',
            '/images/productDatas/TEALIGHT/TEALIGHT HOLDER 2.png',
            '/images/productDatas/TEALIGHT/TEALIGHT HOLDER 3.png',
        ],
        price: '60-₱75',
        alert: "NEW PRODUCT",
        links: [
            {
                name: "Shopee",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Tealight-Candle-Holder-i.381171030.27013222104?sp_atk=5b5b01a6-580f-45fa-8250-145ee863457b&xptdk=5b5b01a6-580f-45fa-8250-145ee863457b"
            },
        
        ]
    }, 

    {
        productName: "Wooden Face Mirror",
        productSlug: "wooden-mirror",
        productTitle: "Wooden Craft",
        "categories": ' Mirror',
        rating: 7,
        description: `Introducing our personalized wooden engraved face mirror, designed to add elegance to your daily routine. This compact, hand-held mirror features a smooth wooden frame, intricately engraved with custom designs such as names, patterns, or meaningful messages. Its high-quality craftsmanship ensures it is both durable and aesthetically pleasing, making it a stylish essential for your beauty kit.
Perfect for personal use or as a thoughtful gift, this face mirror is ideal for touch-ups at home, while traveling, or as part of a vanity display. Its unique engraving makes it a memorable keepsake for special occasions like birthdays, weddings, or bridal showers.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/MIRROR/MIRROR 3.png',
            '/images/productDatas/MIRROR/MIRROR 1.png',
            '/images/productDatas/MIRROR/MIRROR 2.png',
            '/images/productDatas/MIRROR/MIRROR BOX 1 TRUE.png',
            '/images/productDatas/MIRROR/MIRROR BOX 2 TRUE.png',
        ],
        price: '65-₱90',
        alert: "NEW PRODUCT",
        links :[
            {
                name : "Shopee",
                link : "https://shopee.ph/Personalized-Laser-Engraved-Wooden-Face-Mirror-i.381171030.27363939070?sp_atk=e3fe5e23-180e-4b73-9b1a-ca1bea2a9e3b&xptdk=e3fe5e23-180e-4b73-9b1a-ca1bea2a9e3b"
            },
        ]  
    }, 
//     {
//         productName: "Wooden Fan",
//         productSlug: "wooden-fan",
//         productTitle: "Wooden Craft",
//         "categories": ' Fan',
//         rating: 7,
//         description: `Our wooden engraved fan combines timeless craftsmanship with a personalized touch. Each fan is featuring intricate laser-engraved patterns or custom designs, making it both functional and elegant. Its lightweight yet durable structure ensures it is easy to carry, providing a refreshing breeze with style.
// This wooden engraved fan is perfect for weddings, special events, or as a thoughtful gift for loved ones. Its blend of beauty and practicality makes it ideal for outdoor gatherings, bridal showers, or even as a unique party favor, adding a touch of sophistication to any occasion.
//   STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

//   FOR BULK ORDERS: Kindly message us for more discounts!`,
//         pictures: [
//             '/images/productDatas/FAN/FANS 3.png',
//             '/images/productDatas/FAN/FANS 4.png',
//             '/images/productDatas/FAN/FANS 2.png',
//             '/images/productDatas/FAN/FANS 1.png',
//             '/images/productDatas/FAN/FAN 1.png',
//             '/images/productDatas/FAN/FAN 2.png',
//             '/images/productDatas/FAN/FAN 3.png',
//             '/images/productDatas/FAN/FAN 4.png',
//             '/images/productDatas/FAN/FAN PLN 2.png',
//             '/images/productDatas/FAN/FAN PLN 3.png',
//             '/images/productDatas/FAN/FAN PLN 1.png',
//         ],
//         price: '50-85',
//         alert: "NEW PRODUCT",
//         links :[
//             {
//                 name : "Shopee",
//                 link : "asdasdasd"
//             },
//             {
//                 name : "Lazada",
//                 link : "asdasdasd"
//             },
//             {
//                 name : "Website",
//                 link : "asdasdasd"
//             },
//         ]  
//     }, 
    {
        productName: "Wooden Ref Magnet",
        productSlug: "wooden-ref-magnet",
        productTitle: "Wooden Craft",
        "categories": ' Ref Magnet',
        rating: 8,
        description: `Our wooden engraved refrigerator magnets offer a charming way to personalize your space. Each magnet is carefully crafted from high-quality wood and laser-engraved with intricate designs. Available in three unique shapes—circle, square, and heart—you can choose the one that best complements your style. The fine craftsmanship and attention to detail make each piece a small but meaningful accent for your home.
These wooden engraved magnets are perfect for gifting or as a keepsake for special occasions. Whether it's for weddings, birthdays, or housewarming gifts, they add a rustic and personal touch to any fridge or magnetic surface, making them both decorative and functional.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/REF MAGNET/REF MAGNET NEW EDIT 1.png',
            '/images/productDatas/REF MAGNET/REF MAGNET SET 1.png',
            '/images/productDatas/REF MAGNET/REF MAGNET NEW EDIT 2.png',
            '/images/productDatas/REF MAGNET/REF MAGNET SET 5.png',
            '/images/productDatas/REF MAGNET/REF MAGNET SET 2.png',
            '/images/productDatas/REF MAGNET/REF MAGNET SET 4.png',
            '/images/productDatas/REF MAGNET/REF MAGNETS 2.png',
        ],
        price: '45-₱70',
        alert: "NEW PRODUCT",
        links :[
            {
                name : "Shopee",
                link : "asdasdasd"
            },
            {
                name : "Lazada",
                link : "asdasdasd"
            },
            {
                name : "Website",
                link : "asdasdasd"
            },
        ]  
    }, 
    {
        productName: "Wooden Bottle Opener",
        productSlug: "wooden-bottle-opener",
        productTitle: "Wooden Craft",
        "categories": ' Bottle Opener',
        rating: 7,
        description: `Our wooden engraved bottle opener is a stylish and practical addition to any kitchen or bar. Made from high-quality wood and steel, it features personalized engravings that add a unique, rustic charm. The smooth wooden handle ensures a comfortable grip, while the durable opener effortlessly cracks open bottles with ease.
This wooden engraved bottle opener is perfect for personal use or as a thoughtful gift for birthdays, weddings, or housewarming events. Its blend of functionality and personalized design makes it a great addition to home bars, outdoor gatherings, or even as a party favor for special occasions.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/BOTTLE OPENER/ROUND OPENER 1.png',
            '/images/productDatas/BOTTLE OPENER/ROUND OPENER 2.png',
            '/images/productDatas/BOTTLE OPENER/ROUND OPENER 3.png',
            '/images/productDatas/BOTTLE OPENER/OPENER HNDL.png',
            '/images/productDatas/BOTTLE OPENER/OPENER HNDL 2.png',
            '/images/productDatas/BOTTLE OPENER/OPENER HNDL 3.png',
            '/images/productDatas/BOTTLE OPENER/BOTTLE OPENER ROUND.png',
            '/images/productDatas/BOTTLE OPENER/BOTTLE OPNR HNDL SET 1.png',
            '/images/productDatas/BOTTLE OPENER/BOTTLE OPNR HNDL SET 3.png',
            '/images/productDatas/BOTTLE OPENER/BOTTLE OPNR HNDL SET 4.png',
            '/images/productDatas/BOTTLE OPENER/BOTTLE OPNR ROUND SET 1.png',
            '/images/productDatas/BOTTLE OPENER/BOTTLE OPNR ROUND SET 2.png',

        ],
        price: '65-₱80',
        alert: "NEW PRODUCT",
        links: [
            {
                name: "Shopee",
                link: "https://shopee.ph/Personalized-Laser-Engrave-Wooden-Round-Opener-i.381171030.25536753217?sp_atk=9521b373-6970-4c80-bea3-89a1b70339f9&xptdk=9521b373-6970-4c80-bea3-89a1b70339f9"
            },
        ]
    },
    {
        productName: "Wooden Keychain Leather Strap",
        productSlug: "wooden-keychain-leather-strap",
        productTitle: "Wooden Craft",
        "categories": 'Keychain',
        rating: 8,
        description: `Our wooden engraved keychains with leather straps combine rustic charm with a touch of sophistication. Each keychain is crafted from premium wood, engraved with personalized designs or names, and paired with a high-quality faux leather strap for added durability and style. The combination of natural wood and sleek leather makes it a standout accessory for everyday use.
These keychains are perfect for personal use, thoughtful gifts, or special event tokens. Ideal for weddings, corporate giveaways, or anniversaries, they add a personalized and elegant touch to your keys, bags, or luggage, making them both functional and fashionable.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 1.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 3.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 6.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 8.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 4.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 7.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR 5.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR BOX 1.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR BOX 2.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR BOX 5.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR BOX 8.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR BOX 4.png',
            '/images/productDatas/KEYCHAIN LEATHER/KEYCHAIN LTHR BOX 7.png',


        ],
        price: '60-₱95',
        alert: "NEW PRODUCT",
        links :[
            {
                name : "Shopee",
                link : "https://shopee.ph/Personalized-Laser-Engraved-Wooden-Keychain-Leather-Strap-i.381171030.27913430788?sp_atk=57026b68-aae6-44af-862a-72b3fe1db77c&xptdk=57026b68-aae6-44af-862a-72b3fe1db77c"
            },
        ]  
    }, 
    {
        productName: "Wooden Keychain Colored Keyring",
        productSlug: "wooden-keychain-colored-keyring",
        productTitle: "Wooden Craft",
        "categories": 'Keychain',
        rating: 9,
        description: `Our wooden engraved keychains with colored keyrings offer a unique blend of personalization and vibrant style. Each keychain is crafted from high-quality wood and engraved with custom designs, making it a thoughtful accessory. Paired with a durable keyring available in three colors—pink, blue, and black—you can choose the perfect hue to suit your style or occasion.
These keychains are ideal for personal use, gifting, or event souvenirs. Whether for birthdays, weddings, or corporate giveaways, they provide a personalized and stylish touch to your keys, bags, or backpacks, making them a practical and eye-catching keepsake.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAINS 9.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAINS 7.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 3.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 12.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 11.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 8.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 4.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 5.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 6.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD BLUE.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD 7.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD BOX 2.png',
            '/images/productDatas/KEYCHAIN COLORED/KEYCHAIN CLRD BOX 3.png',
        ],
        price: '50-₱85',
        alert: "NEW PRODUCT",
        links :[
            {
                name : "Shopee",
                link : "https://shopee.ph/Personalized-Laser-Engraved-Wooden-Keychain-w-Colored-Keyring-i.381171030.26113424139?sp_atk=4a9c5003-dd6b-4e74-a827-0da70dc85aca&xptdk=4a9c5003-dd6b-4e74-a827-0da70dc85aca"
            },
        ]  
    }, 
    {
        productName: "Wooden Keychain Plain Keyring",
        productSlug: "wooden-keychain-plain-keyring",
        productTitle: "Wooden Craft",
        "categories": 'Keychain',
        rating: 7,
        description: `Our wooden engraved keychains with plain keyrings offer a simple yet elegant touch to your everyday essentials. Each keychain is crafted from premium wood and engraved with personalized designs, names, or messages, making it a unique and thoughtful accessory. The plain metal keyring ensures durability and a minimalist style, perfect for those who prefer a classic look.
These keychains are ideal for personal use, gifts, or event keepsakes. Whether for weddings, corporate giveaways, or daily use, they add a personalized yet timeless charm to your keys, bags, or backpacks, making them a functional and meaningful accessory.
  STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

  FOR BULK ORDERS: Kindly message us for more discounts!`,
        pictures: [
            '/images/productDatas/KEYCHAIN/KEYCHAIN NRML 1.png',
            '/images/productDatas/KEYCHAIN/KEYCHAIN NRML 2.png',
            '/images/productDatas/KEYCHAIN/KEYCHAIN NRML BOX 2.png',
            '/images/productDatas/KEYCHAIN/KEYCHAIN NRML BOX 3.png',
            '/images/productDatas/KEYCHAIN/KEYCHAIN NRML SET 1.png',
            '/images/productDatas/KEYCHAIN/KEYCHAIN NRML BOX 4.png',
        ],
        price: '40-₱75',
        alert: "NEW PRODUCT",
        links: [
            {
                name: "Shopee",
                link: "https://shopee.ph/Personalized-Laser-Engraved-Wooden-Keychain-i.381171030.29013413735?sp_atk=c68dc129-f47e-4a2c-9c70-b5a90c4b9022&xptdk=c68dc129-f47e-4a2c-9c70-b5a90c4b9022"
            },
        ]
    }, 

//     {
//         productName: "Mini Standee",
//         productSlug: "mini-standee",
//         productTitle: "Printed Craft",
//         "categories": 'Standee',
//         rating: 7,
//         description: `Add a personal touch to your space with our Printed Mini Standee with Wooden Easel. This charming, customized standee features a high-quality print mounted on a mini easel made from natural wood, making it a delightful addition to any desk, shelf, or table. Whether it's a favorite quote, photo, or design, the durable standee is easy to display and perfect for adding character to any corner.
// Ideal for gifting, home decor, or even as event giveaways, these mini standees are great for personalizing workspaces, living rooms, or special occasions like birthdays and weddings.Compact and stylish, they're an effortless way to showcase memories or designs in any setting.
//   STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

//   FOR BULK ORDERS: Kindly message us for more discounts!`,
//         pictures: [
//         '/images/productDatas/SIGNAGE PRINT 3.png',
//         '/images/productDatas/SIGNAGE PRINT 4.png',
//         '/images/productDatas/SIGNAGE PRINT.png',
//     ],
//     price : '50-75',
//     alert : "NEW PRODUCT",
//         links :[
//             {
//                 name : "Shopee",
//                 link : "asdasdasd"
//             },
//             {
//                 name : "Lazada",
//                 link : "asdasdasd"
//             },
//             {
//                 name : "Website",
//                 link : "asdasdasd"
//             },
    //         ]
    //     },

//     {
//         productName: "Customized Hang Tag",
//         productSlug: "customized-hang-tag",
//         productTitle: "Printed Craft",
//         "categories": 'Prints',
//         rating: 5,
//         description: `Enhance your personal touch with our customized printed hang tags, perfect for special occasions or personal projects. Made from high-quality materials, these tags can showcase your unique designs, heartfelt messages, or personal logos in vibrant colors. 
// Ideal for weddings, birthdays, or any celebration, our personalized hang tags make it easy to add a special message to your items. Whether you're labeling homemade treats, creating custom gift tags, or adding a unique touch to party favors, these versatile tags provide an elegant solution that truly reflects your personality and creativity.

// STRICTLY NO CANCELLATION OF ORDERS AS THIS IS PERSONALIZED PRODUCT!

// FOR BULK ORDERS: Kindly message us for more discounts!`,
//         pictures: [
//             '/images/productDatas/HANG TAG/HANG TAG 1.png',
//             '/images/productDatas/HANG TAG/HANG TAG 2.png',
//             '/images/productDatas/HANG TAG/HANG TAG 3.png',
//         ],
//         price: '72.00',
//         alert: "NEW PRODUCT",
//         links: [
//             {
//                 name: "Shopee",
//                 link: "asdasdasd"
//             },
//             {
//                 name: "Lazada",
//                 link: "asdasdasd"
//             },
//             {
//                 name: "Website",
//                 link: "asdasdasd"
//             },
//         ]
//     },




];

export default productArray;