import React from 'react';
import { Button } from "flowbite-react";

function AboutUs() {

    return (
        <>
            <div className="flex h-full items-center justify-center   text-white text-center "
                style={{
                    backgroundImage: `url(/images/aboutus.png)`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: '700px',
                    width: '100%',
                    position: 'relative'
                }}
            >
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',  // Dark overlay with 50% opacity
                }}></div>
                <div className='z-50 xl:mx-96 mx-7'>
                    <p className='text-gray-200'>ABOUT US</p>
                    <h1 className='lg:text-6xl text-2xl'>A Journey of Passion and Perseverance, From Humble Beginnings to Meaningful Creations</h1>
                </div>

            </div>
            <div className='xl:mx-96 xl:my-20 mx-10 my-10'>
                <h1 className='font-extrabold text-center'>A Journey of Faith, Resilience, and New Beginnings</h1>
                <br/>
                <br/>
                <p className='font-serif'>
                    In 2020, like so many others, my world was turned upside down. The pandemic hit hard, and the
                    restaurant where I had worked for years was forced to temporarily close. I was one of the thousands of
                    people affected by the sudden layoffs. In those uncertain times, fear and anxiety clouded my mind, but I
                    knew I had to keep moving forward—for myself and my future.
                    <br />
                    <br />
                    During that time, I discovered resilience I didn’t know I had. In the face of financial hardship, I decided to
                    venture into a small reselling business. This was how &quot;Swift Finds&quot; was born, offering Sintra board wall
                    decors and gifts, a name inspired by the ease and simplicity I wanted for my customers. Initially, it
                    wasn’t something I planned to pursue long-term, but it helped me survive the uncertainty of the
                    moment. Though it thrived for months, personal matters soon forced me to stop. I found myself back in
                    the workforce, returning to a job that no longer brought me fulfillment.
                    <br />
                    <br />
                    For almost 10 years, I worked in the hospitality industry. I stayed in roles that no longer felt like a fit for
                    me. Eventually, I felt burnt out, suffocated, and unsure of the direction my life was taking. There was no
                    joy in what I was doing, and I felt disconnected from the path I had once pursued. It was a painful
                    realization, but it was a necessary turning point. I decided to walk away, not only from my job but from
                    the city that had once been filled with opportunity for me. I returned to my hometown, unsure of what
                    was next.
                    <br></br>
                    <br></br>
                    In those moments of uncertainty, I tried applying for work overseas, hoping that would be the new
                    beginning I needed. But God had other plans. While waiting, I was blessed with something more
                    precious than I could’ve imagined—time with my family. It was during this period that I truly learned to
                    lean on my faith. I prayed endlessly, seeking clarity, guidance, and strength. I struggled with doubts and
                    asked God why my plans weren’t unfolding as I had hoped. The more I sought answers, the more I
                    began to realize that sometimes, God&#39;s plans are greater than our own.
                    <br></br>

                    Then 2024 came—a year that marked a new beginning. After years of prayers, tears, and moments of
                    hopelessness, I was given the chance to do what truly makes my heart full. It was clear: God didn’t give
                    me what I wanted back then because He had something much better in store for me. &quot;Swift Finds&quot; has
                    now become more than just a business to me. It’s a symbol of my journey, my faith, and the belief that
                    when the time is right, God will make everything happen.
                    <br></br>

                    Through all the struggles, I’ve held onto the Bible verse that continues to inspire me: &quot;When the time is
                    right, I, the Lord, will make it happen.&quot; This verse resonates deeply with me. It reminds me that despite
                    the hardships and delays, God’s timing is always perfect. We might not understand why things don’t
                    <br></br>
                    <br></br>

                    work out as we plan, but if we trust the journey and keep our faith, we will see His greater purpose
                    unfold.
                    <br></br>
                    <br></br>

                    Starting this business is a leap of faith for me, and I’m walking this path with my loved ones by my side,
                    and the support from the One above. I’m reminded every day that God is good, that He loves us, and
                    that we just need to trust in Him. This journey has not been easy, and I know there will be more
                    challenges ahead, but I face them with faith in my heart. For those of you who are struggling, remember
                    that there is beauty in waiting, and God’s plans are always greater than we can imagine.
                    <br></br>
                    <br></br>

                    Let us put our faith, love, and trust in Him, for He is the ultimate guide to where we are meant to be.</p>
            </div>
        </>
    );
}

export default AboutUs;